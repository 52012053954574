import { useMemo, useState } from 'react';
import { paramCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Skeleton,
} from '@mui/material';

import { PATH_DASHBOARD } from '../../../routes/paths';
import useTabs from '../../../shared/hooks/useTabs';
import useSettings from '../../../shared/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../../shared/hooks/useTable';
import { UserManager, UserRole } from '../../../shared/@types/user';
import Page from '../../../shared/components/Page';
import Iconify from '../../../shared/components/Iconify';
import Scrollbar from '../../../shared/components/Scrollbar';
import HeaderBreadcrumbs from '../../../shared/components/HeaderBreadcrumbs';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedActions,
} from '../../../shared/components/table';
import { UserTableToolbar, UserTableRow } from './sections/list';
import useFetch from '../../../shared/hooks/useFetch';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import { HOST_API } from '../../../config';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'username', label: 'Name', align: 'left' },
  { id: 'user_surname', label: 'Surname', align: 'left' },
  { id: 'user_role', label: 'User Role', align: 'left' },
  // { id: 'isRealtor', label: 'is Realtor', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: '' },
];
export default function UserList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const {
    data: tableData = [],
    isLoading,
    refreshFetch,
  } = useFetch<UserManager[]>(HOST_API + API_ENDPOINTS.ALL_USERS);

  const { data: roleDataSource } = useFetch<UserRole[]>(HOST_API + API_ENDPOINTS.ALL_ROLES);

  const roleData = useMemo(() => {
    if (!roleDataSource) {
      return [];
    }

    return [...roleDataSource];
  }, [roleDataSource]);

  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');

  // const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id: string) => {
    API.delete<number>(API_ENDPOINTS.DELETE_USER.replace(':id', id)).then(() => {
      refreshFetch();
    });
  };

  const onChangeRealtorFlag = (data: UserManager) => {
    const formData = new FormData();

    Object.keys(data).forEach((formProp) => {
      if (formProp === 'user_role') return;
      // @ts-ignore
      if (formProp === 'avatarUrl' && data[formProp]?.name) {
        // @ts-ignore
        formData.append('files', data[formProp], data[formProp].name);
      } else {
        // @ts-ignore
        formData.append(formProp, data[formProp]);
      }
    });

    API.put<any>(API_ENDPOINTS.UPDATE_USER, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(() => {
      refreshFetch();
    });
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row?.id));
    setSelected([]);
    // setTableData(deleteRows);
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.user.edit(id));
  };

  if (isLoading) {
    return <Skeleton />;
  }

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) || (!dataFiltered.length && !!filterRole);

  return (
    <Page title="User: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="User List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: 'List' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.user.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New User
            </Button>
          }
        />

        <Card>
          <UserTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={roleData}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) => {
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row?.id)
                    );
                  }}
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) => {
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row?.id)
                    );
                  }}
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onChangeRealtorFlag={(isRealtor: boolean) =>
                          onChangeRealtorFlag({ ...row, isRealtor })
                        }
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
            <Box
              style={{ float: 'right' }}
              sx={{ px: 3, py: 1.5, top: '7px', left: '115px', position: { md: 'absolute' } }}
            >
              All Users ({dataFiltered.length})
            </Box>
            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterRole,
}: {
  tableData: UserManager[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterRole: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter((item: Record<string, any>) => {
      const saerchBysurname =
        item.user_surname.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
      const saerchByUserName = item.username.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;

      return saerchBysurname || saerchByUserName;
    });
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter((item: Record<string, any>) => {
      return item.user_role.find((role: any) => role.type === filterRole);
    });
  }
  return tableData;
}
