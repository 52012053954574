import { HOST_API } from '../../../config';
import { API_ENDPOINTS } from '../../../shared/utils/api';
import { EstateObject } from '../../../shared/@types/blog';
import orderBy from 'lodash/orderBy';

type Params = {
  price: string | null;
  keyword: string | null;
  state: string | null;
  city: string | null;
  bedrooms: string | null;
  page: number;
  offset: number;
};
export const getRequestUrl = ({ price, keyword, state, city, bedrooms, page, offset }: Params) => {
  let queryParams = '?';
  if (price) {
    queryParams += `&price=${price}`;
  }

  if (keyword) {
    queryParams += `&keyword=${keyword}`;
  }

  if (state) {
    queryParams += `&state=${state}`;
  }

  if (city) {
    queryParams += `&city=${city}`;
  }

  if (bedrooms) {
    queryParams += `&bedrooms=${bedrooms}`;
  }

  queryParams += `&page=${page}`;
  queryParams += `&offset=${(page - 1) * offset}`;
  queryParams += `&limit=${offset}`;

  return HOST_API + API_ENDPOINTS.ALL_REAL_ESTATE_PUBLIC + queryParams;
};

export const applySort = (posts: EstateObject[], sortBy: string) => {
  if (sortBy === 'latest') {
    return orderBy(posts, ['createdAt'], ['desc']);
  }
  if (sortBy === 'oldest') {
    return orderBy(posts, ['createdAt'], ['asc']);
  }
  if (sortBy === 'popular') {
    return orderBy(posts, ['view'], ['desc']);
  }
  return posts;
};
