import React from 'react';
import { Navigate } from 'react-router-dom';

import AuthGuard from '../../shared/guards/AuthGuard';
import DashboardLayout from '../../shared/layouts/dashboard';

import UserProfile from './User/UserProfile';
import UserAccount from './User/UserAccount';
import UserList from './User/UserList';
import UserCreateAndUpdate from './User/UserCreateAndUpdate';
import RealEstate from './RealEstate/RealEstate';
import RealEstateCreateAndUpdate from './RealEstate/RealEstateCreateAndUpdate';
import RealEstateList from './RealEstate/RealEstateList';
import RealEstateInquiries from './RealEstate/sections/RealEstateInquiries';

export default {
  path: 'dashboard',
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      children: [{ element: <Navigate to="/dashboard/user/profile" replace />, index: true }],
    },
    {
      path: 'user',
      children: [
        { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
        { path: 'profile', element: <UserProfile /> },
        { path: 'list', element: <UserList /> },
        { path: 'account', element: <UserAccount /> },
        // { path: 'account', element: <UserProfile /> },
        { path: 'new', element: <UserCreateAndUpdate /> },
        { path: ':id/edit', element: <UserCreateAndUpdate /> },
      ],
    },
    {
      path: 'real-estate',
      children: [
        { element: <Navigate to="/dashboard/real-estate/list" replace />, index: true },
        { path: 'list', element: <RealEstateList /> },
        { path: 'new', element: <RealEstateCreateAndUpdate /> },
        { path: 'inquiries', element: <RealEstateInquiries /> },
        { path: ':id/edit', element: <RealEstateCreateAndUpdate /> },
        { path: ':id', element: <RealEstate /> },
      ],
    },
  ],
};
