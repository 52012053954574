function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function priceFormatSlider(value: number) {
  const scaledValue = numberWithCommas(value);

  if (value === 20000001) {
    return `$2M+`;
  }

  return `$ ${scaledValue}`;
}

export function priceFormat(value: number) {
  const scaledValue = numberWithCommas(value);

  return `$ ${scaledValue}`;
}
