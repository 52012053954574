import { styled } from '@mui/material/styles';

import Page from '../../../shared/components/Page';

import { HomeHero, HomeMinimal } from './sections';

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function HomePage() {
  return (
    <Page title="The starting point for your next project">
      <HomeHero />
      <ContentStyle>
        <HomeMinimal />
      </ContentStyle>
    </Page>
  );
}
