import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container } from '@mui/material';

import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import cssStyles from '../../utils/cssStyles';
import { HEADER } from '../../../config';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import { ThemeSwitch } from '../../sections/ThemeSwitch';

import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const { user } = useAuth();
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  console.log('MainHeader -> user', user);

  const menu = useMemo(() => {
    return navConfig.filter((item) => {
      const isCheckAuthExist = !!Object.getOwnPropertyDescriptor(item, 'auth');

      if (!isCheckAuthExist) return true;
      if (user) return item.auth;
      if (!user) return !item.auth;
    });
  }, [user]);

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={menu} />}
          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={menu} />}
          <Box sx={{ flexGrow: 1 }} />
          <ThemeSwitch />
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
