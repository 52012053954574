import React from 'react';
import { disableCache } from '@iconify/react';
import Router from './routes';
import ThemeProvider from './theme';
import ThemeSettings from './shared/components/settings';
import ScrollToTop from './shared/components/ScrollToTop';
import { ProgressBarStyle } from './shared/components/ProgressBar';
import NotistackProvider from './shared/components/NotistackProvider';
import MotionLazyContainer from './shared/components/animate/MotionLazyContainer';

disableCache('all');

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
