import Iconify from '../../components/Iconify';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Featured Listings',
    path: PATH_PAGE.realEstate,
    icon: <Iconify icon={'dashicons:admin-multisite'} {...ICON_SIZE} />,
  },
  {
    title: 'Dashboard',
    path: PATH_DASHBOARD.root,
    auth: true,
    icon: <Iconify icon={'carbon:gui-management'} {...ICON_SIZE} />,
  },
  {
    title: 'Login',
    icon: <Iconify icon={'majesticons:login'} {...ICON_SIZE} />,
    path: PATH_AUTH.login,
    auth: false,
  },
  {
    title: 'Register',
    icon: <Iconify icon={'mingcute:user-add-fill'} {...ICON_SIZE} />,
    path: PATH_AUTH.register,
    auth: false,
  },
];

export default menuConfig;
