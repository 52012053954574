import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';

import useSettings from '../../../shared/hooks/useSettings';
import useIsMountedRef from '../../../shared/hooks/useIsMountedRef';
import { EstateObject } from '../../../shared/@types/blog';
import Page from '../../../shared/components/Page';
import Markdown from '../../../shared/components/Markdown';
import { SkeletonPost } from '../../../shared/components/skeleton';
import Image from '../../../shared/components/Image';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import CarouselThumbnail from '../../../shared/components/carousel-types/CarouselThumbnail';
import { fDate } from '../../../shared/utils/formatTime';
import Iconify from '../../../shared/components/Iconify';
import { priceFormat } from '../../../shared/utils/priceFormat';
import RealtorSendMessageDialog from './sections/RealtorSendMessageDialog';

// ----------------------------------------------------------------------

export default function RealEstatePublic() {
  const { themeStretch } = useSettings();

  const isMountedRef = useIsMountedRef();

  const { id } = useParams();

  const [post, setPost] = useState<EstateObject | null>(null);

  const [error, setError] = useState(null);

  const getPost = useCallback(async () => {
    try {
      // @ts-ignore
      const response = await API.get<any>(API_ENDPOINTS.GET_REAL_ESTATE.replace(':id', id));

      if (isMountedRef.current) {
        // @ts-ignore
        setPost(response?.data.estateObject);
      }
    } catch (error: any) {
      setError(error.message);
    }
  }, [isMountedRef, id]);

  useEffect(() => {
    getPost();
  }, []);

  return (
    <Page title={`Featured Item Detail: ${post?.title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'} style={{ marginTop: 100 }}>
        {post && (
          <>
            <Box sx={{ position: 'relative' }}>
              <CarouselThumbnail images={post.images} />
            </Box>

            <Grid container spacing={1} marginBottom={2}>
              <Grid item xs={8} md={8} sm={12}>
                <Typography variant="h3" sx={{ color: 'text.primary' }}>
                  {post.title}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  <Iconify icon={'carbon:location-filled'} marginRight={1} />
                  {post.address}
                </Typography>

                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:money'} marginRight={1} /> Asking Price:{' '}
                    {priceFormat(post.price)}
                  </Grid>

                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:th-large'} marginRight={1} /> Square Feet: {post.sqft}
                  </Grid>

                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:square'} marginRight={1} /> Lot Size: {post.lotSize}
                  </Grid>

                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:calendar'} marginRight={1} /> Listing Date:{' '}
                    {fDate(post.updatedAt)}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:car'} marginRight={1} /> Garage: {post.garage}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:bed'} marginRight={1} /> Bedrooms: {post.bedrooms}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:bath'} marginRight={1} /> Bathrooms: {post.bathrooms}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'fa:user'} marginRight={1} /> Realtor: {post?.realtor?.username}{' '}
                    {post?.realtor?.user_surname}
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ paddingTop: 5 }}>
                      <Markdown children={post.description} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs md sm={12}>
                <Card sx={{ p: 3 }}>
                  <Image src={post.realtor.avatarUrl} sx={{ height: 240, mb: 3 }} />

                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    Property Realtor
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    {post?.realtor?.username} {post?.realtor?.user_surname}
                  </Typography>
                  <RealtorSendMessageDialog />
                </Card>
              </Grid>
            </Grid>
          </>
        )}

        {!post && !error && <SkeletonPost />}
      </Container>
    </Page>
  );
}
