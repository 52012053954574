import { Box, Grid, Stack, Typography } from '@mui/material';

import { SkeletonPostItem } from '../../../../../shared/components/skeleton';
import React, { useEffect, useState } from 'react';
import { API, API_ENDPOINTS } from '../../../../../shared/utils/api';
import RealEstateCardSimple from '../../../../web/RealEstate/sections/RealEstateCardSimple';
import EmptyContent from '../../../../../shared/components/EmptyContent';
import { FavoriteEvent } from '../../../../web/RealEstate/sections/types';

export default function ProfileFavorites() {
  const [favoriteList, setFavoriteList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateFavoriteList = () => {
    API.get(API_ENDPOINTS.ALL_REAL_ESTATE_FAVORITES).then(({ data }: any) => {
      setIsLoading(false);
      setFavoriteList(data.list);
    });
  };

  useEffect(updateFavoriteList, []);

  const onToggleItemToFavorite = ({ estateId, favoriteId }: FavoriteEvent) => {
    if (favoriteId) {
      API.delete(
        API_ENDPOINTS.DELETE_FAVORITE_REAL_ESTATE.replace(':id', favoriteId as unknown as string)
      ).then(updateFavoriteList);
    } else {
      API.post(API_ENDPOINTS.ADD_FAVORITE_REAL_ESTATE, { estateId }).then(updateFavoriteList);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Stack spacing={3} marginBottom={2}>
          <Typography variant="h4">Favorites ({favoriteList.length})</Typography>
        </Stack>

        <Grid container spacing={3} position="relative">
          {favoriteList.map((post: any, index) => {
            return (
              <Grid key={post.id} item xs={12} sm={6} md={4}>
                <RealEstateCardSimple
                  post={post}
                  index={index}
                  favoriteId={post.favoriteId}
                  onToggleItemToFavorite={onToggleItemToFavorite}
                />
              </Grid>
            );
          })}

          {isLoading && [...Array(12)].map((_, index) => <SkeletonPostItem key={index} />)}

          {!isLoading && !favoriteList.length && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '400px',
              }}
            >
              <EmptyContent
                title="No Data"
                sx={{
                  '& span.MuiBox-root': { height: 160 },
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
