import { Link as RouterLink } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Card, Avatar, Typography, CardContent, Stack } from '@mui/material';

import { PATH_PAGE } from '../../../../routes/paths';
import useResponsive from '../../../../shared/hooks/useResponsive';
import { fDate } from '../../../../shared/utils/formatTime';
import { EstateObject } from '../../../../shared/@types/blog';
import Image from '../../../../shared/components/Image';
import TextMaxLine from '../../../../shared/components/TextMaxLine';
import SvgIconStyle from '../../../../shared/components/SvgIconStyle';

const OverlayStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.4),
}));

type Props = {
  post: EstateObject;
  index?: number;
};

export default function RealEstateCard({ post, index }: Props) {
  const isDesktop = useResponsive('up', 'md');

  const { realtor, createdAt, id, images, title } = post;

  const latestPost = index === 0 || index === 1 || index === 2;

  if (isDesktop && latestPost) {
    return (
      <Card>
        <Avatar
          alt={realtor?.username}
          src={realtor?.avatarUrl}
          sx={{
            zIndex: 9,
            top: 24,
            left: 24,
            width: 40,
            height: 40,
            position: 'absolute',
          }}
        />
        <PostContent
          title={title}
          view={1}
          comment={1}
          share={2}
          createdAt={createdAt}
          index={index}
          id={id}
        />
        <OverlayStyle />

        <Image alt="cover" src={images[0]} sx={{ height: 360 }} />
      </Card>
    );
  }

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        <SvgIconStyle
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: 'absolute',
            color: 'background.paper',
          }}
        />
        <Avatar
          alt={realtor?.username}
          src={realtor?.avatarUrl}
          sx={{
            left: 24,
            zIndex: 9,
            width: 32,
            height: 32,
            bottom: -16,
            position: 'absolute',
          }}
        />
        <Image alt="cover" src={images[0]} ratio="4/3" />
      </Box>

      <PostContent id={id} title={title} view={1} comment={2} share={3} createdAt={createdAt} />
    </Card>
  );
}

// ----------------------------------------------------------------------

type PostContentProps = {
  title: string;
  view: number;
  comment: number;
  share: number;
  createdAt: Date | string | number;
  index?: number;
  id: string;
};

export function PostContent({ title, createdAt, index, id }: PostContentProps) {
  const isDesktop = useResponsive('up', 'md');

  const linkTo = PATH_PAGE.realEstateIdView(id);

  const latestPostLarge = index === 0;
  const latestPostSmall = index === 1 || index === 2;

  return (
    <CardContent
      sx={{
        pt: 4.5,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
      }}
    >
      <Typography
        gutterBottom
        variant="caption"
        component="div"
        sx={{
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
        }}
      >
        {fDate(createdAt)}
      </Typography>

      <Link to={linkTo} color="inherit" component={RouterLink}>
        <TextMaxLine
          variant={isDesktop && latestPostLarge ? 'h5' : 'subtitle2'}
          line={2}
          persistent
        >
          {title}
        </TextMaxLine>
      </Link>

      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="flex-end"
        sx={{
          mt: 3,
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
        }}
      ></Stack>
    </CardContent>
  );
}
