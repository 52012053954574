import { Theme } from '@mui/material/styles';

export default function Accordion(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: '8px',
        },
      },
    },
  };
}
