import React, { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import useAuth from '../shared/hooks/useAuth';
import LoadingScreen from '../shared/components/LoadingScreen';
import authRoutes from './../modules/auth/routes';
import dashboardRoutes from './../modules/dashboard/routes';
import wedRoutes from './../modules/web/routes';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    authRoutes,
    dashboardRoutes,
    wedRoutes,
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}

const UserProfile = Loadable(lazy(() => import('../modules/dashboard/User/UserProfile')));
