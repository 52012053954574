import * as Yup from 'yup';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Stack, IconButton, InputAdornment, Alert, Box, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useAuth from '../../../../shared/hooks/useAuth';
import useIsMountedRef from '../../../../shared/hooks/useIsMountedRef';

import Iconify from '../../../../shared/components/Iconify';
import {
  FormProvider,
  RHFCheckbox,
  RHFSwitch,
  RHFTextField,
} from '../../../../shared/components/hook-form';

type FormValuesProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isRealtor: boolean;
  afterSubmit?: string;
};

export default function RegisterForm() {
  const { register } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    isRealtor: Yup.string().required('Role is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isRealtor: false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    console.log('default::onSubmit -> data', data);

    try {
      await register(data.email, data.password, data.firstName, data.lastName, data.isRealtor);
    } catch (error: any) {
      // reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error!.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Stack>

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFCheckbox name="isRealtor" label="Register as Realtor" />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
