import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Card, Container, Typography } from '@mui/material';

import { PATH_DASHBOARD } from '../../../routes/paths';

import useSettings from '../../../shared/hooks/useSettings';
import useIsMountedRef from '../../../shared/hooks/useIsMountedRef';

import { EstateObject } from '../../../shared/@types/blog';

import Page from '../../../shared/components/Page';
import Markdown from '../../../shared/components/Markdown';
import HeaderBreadcrumbs from '../../../shared/components/HeaderBreadcrumbs';
import { SkeletonPost } from '../../../shared/components/skeleton';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import CarouselThumbnail from '../../../shared/components/carousel-types/CarouselThumbnail';

// ----------------------------------------------------------------------

export default function RealEstate() {
  const { themeStretch } = useSettings();

  const isMountedRef = useIsMountedRef();

  const { id } = useParams();

  const [post, setPost] = useState<EstateObject | null>(null);

  const [error, setError] = useState(null);

  const getPost = useCallback(async () => {
    try {
      // @ts-ignore
      const response = await API.get<any>(API_ENDPOINTS.GET_REAL_ESTATE.replace(':id', id));

      console.log(' -> response', response);

      if (isMountedRef.current) {
        // @ts-ignore
        setPost(response?.data.estateObject);
      }
    } catch (error: any) {
      setError(error.message);
    }
  }, [isMountedRef, id]);

  useEffect(() => {
    getPost();
  }, []);

  return (
    <Page title="Blog: Post Details">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Object Details"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Real Estate', href: PATH_DASHBOARD.realEstate.root },
            // { name: setenceCase(title as string) },
          ]}
        />

        {post && (
          <Card>
            <Box sx={{ position: 'relative' }}>
              <CarouselThumbnail images={post.images} />
            </Box>

            <Box sx={{ p: { xs: 3, md: 5 } }}>
              <Typography variant="h6" sx={{ mb: 5 }}>
                {post.title}
              </Typography>

              <Markdown children={post.description} />
              {/*<Box sx={{ mb: 5, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>*/}
              {/*  <Pagination count={8} color="primary" />*/}
              {/*</Box>*/}
            </Box>
          </Card>
        )}

        {!post && !error && <SkeletonPost />}

        {error && <Typography variant="h6">404 {error}!</Typography>}

        {/*<BlogPostRecent posts={recentPosts} />*/}
      </Container>
    </Page>
  );
}
