import * as Yup from 'yup';
import React, { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';

import { fData } from '../../../../shared/utils/formatNumber';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { UserManager } from '../../../../shared/@types/user';
import { CustomFile } from '../../../../shared/components/upload';
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../shared/components/hook-form';
import { API, API_ENDPOINTS } from '../../../../shared/utils/api';

interface FormValuesProps extends Omit<UserManager, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

export default function UserNewForm() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    username: Yup.string().required('Name is required'),
    user_surname: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email(),
    password: Yup.string().required('Password is required'),
    check_password: Yup.string().required('Password is required'),
  });

  const defaultValues = useMemo(
    () => ({
      username: '',
      user_surname: '',
      email: '',
      avatarUrl: '',
      isRealtor: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const formData = new FormData();

      Object.keys(data).forEach((formProp) => {
        if (formProp === 'avatarUrl' && data[formProp]) {
          // @ts-ignore
          formData.append('files', data[formProp], data[formProp].name);
        } else {
          // @ts-ignore
          formData.append(formProp, data[formProp]);
        }
      });

      await API.post<any>(API_ENDPOINTS.CREATE_USER, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      reset();
      enqueueSnackbar('Create success!');
      navigate(PATH_DASHBOARD.user.list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              {/*<RHFSwitch name="isRealtor" label="Is User Realtor" />*/}
              <RHFTextField name="username" label="Name" />
              <RHFTextField name="user_surname" label="Surname" />
              <RHFTextField name="email" label="Email Address" />
              <br />
              <br />
              <br />
              <br />
              <RHFTextField name="password" label="Password" />
              <RHFTextField name="check_password" label="Check Password" />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Create User
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
