import { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Grid, TextField, MenuItem, Slider, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { PATH_PAGE } from '../../../../routes/paths';
import Iconify from '../../../../shared/components/Iconify';
import { priceFormatSlider } from '../../../../shared/utils/priceFormat';

const StyledTextField = styled(TextField)`
  //.MuiInputBase-root {
  //  background-color: #fff;
  //}
`;

const badrooms = [
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' },
  { value: '6', label: '6+' },
  { value: '7', label: '7+' },
  { value: '8', label: '8+' },
  { value: '9', label: '9+' },
  { value: '10', label: '10+' },
];
const states = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

const minDistance = 100000;

export const HomeFilterForm = () => {
  const [priceValue, setPriceValue] = useState<number[]>([500000, 10000000]);
  const [keyWordValue, setKeyWordValue] = useState<string>('');
  const [bedRoomsValue, setBedRoomsValue] = useState<string>('');
  const [stateValue, setStateValue] = useState<string>('');
  const [cityValue, setCityValue] = useState<string>('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleKeyWordValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWordValue(event.target.value);
  };

  const handleBedRoomsValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBedRoomsValue(event.target.value);
  };

  const handleStatedValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateValue(event.target.value);
  };

  const handleCityValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCityValue(event.target.value);
  };

  const handlePriceChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceValue([Math.min(newValue[0], priceValue[1] - minDistance), priceValue[1]]);
    } else {
      setPriceValue([priceValue[0], Math.max(newValue[1], priceValue[0] + minDistance)]);
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledTextField
            fullWidth
            label="Search"
            value={keyWordValue}
            onChange={handleKeyWordValueChange}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            fullWidth
            label="Bedrooms"
            select
            value={bedRoomsValue}
            onChange={handleBedRoomsValueChange}
          >
            {badrooms.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              p: 1,
              borderRadius: 1,
              bgcolor: theme.palette.background.paper,
            }}
          >
            <Typography color={'#2065D1'}>
              Price {priceFormatSlider(priceValue[0])} - {priceFormatSlider(priceValue[1])}
            </Typography>

            <Box
              sx={{
                pr: 1,
                pl: 1,
              }}
            >
              <Slider
                value={priceValue}
                min={0}
                step={100000}
                max={20000001}
                onChange={handlePriceChange}
                getAriaValueText={priceFormatSlider}
                valueLabelFormat={priceFormatSlider}
                valueLabelDisplay="auto"
                disableSwap
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <StyledTextField
            fullWidth
            label="State"
            select
            value={stateValue}
            onChange={handleStatedValueChange}
          >
            {states.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item xs>
          <StyledTextField
            fullWidth
            label="City"
            value={cityValue}
            onChange={handleCityValueChange}
          />
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <Button
            size="large"
            fullWidth
            variant="contained"
            // to={PATH_PAGE.realEstate}
            onClick={() => {
              console.log(' ->         to={PATH_PAGE.realEstate}');
              const params = {} as any;
              if (priceValue) {
                params['price'] = priceValue.join('-');
              }
              if (keyWordValue) {
                params['keyword'] = keyWordValue;
              }
              if (bedRoomsValue) {
                params['bedrooms'] = bedRoomsValue;
              }
              if (stateValue) {
                params['state'] = stateValue;
              }
              if (cityValue) {
                params['city'] = cityValue;
              }

              // console.log(' ->  PATH_PAGE.realEstate + params', PATH_PAGE.realEstate + params);
              navigate({
                pathname: PATH_PAGE.realEstate,
                search: `?${createSearchParams(params)}`,
              });
            }}
            startIcon={<Iconify icon={'eva:search-fill'} width={20} height={20} />}
          >
            Start Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
