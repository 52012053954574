import { m } from 'framer-motion';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Stack, StackProps } from '@mui/material';
import { MotionContainer, varFade } from '../../../../shared/components/animate';
import { HomeFilterForm } from './HomeFilterForm';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props: StackProps) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 590,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left',
    },
  })
);

const HeroOverlayStyle = styled(m.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export default function HomeHero() {
  return (
    <MotionContainer>
      <RootStyle>
        <HeroOverlayStyle alt="overlay" src="/assets/home/showcase.jpeg" variants={varFade().in} />
        <Container>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <ContentStyle>
                <m.div variants={varFade().inRight}>
                  <Typography
                    variant="h1"
                    sx={{ color: 'common.white', textShadow: '1px 1px 0px #fff' }}
                  >
                    The Perfect Homes
                    <br />
                    By one <b>click</b>
                    <br /> with
                    <Typography component="span" variant="h1" sx={{ color: 'primary.main' }}>
                      &nbsp;Delek Homes
                    </Typography>
                  </Typography>
                </m.div>

                <m.div variants={varFade().inRight}>
                  <Typography sx={{ color: 'common.white', textShadow: '1px 1px #2065D1' }}>
                    Property Searching Just Got So Easy
                    <br />
                    Welcome to Delek Homes. The home of homes!
                    <br />
                    We take it seriously. Finding the right home should be no brainer. Use advanced
                    search feature to find the best home based on your criterias.
                    <br />
                    Or add your department and our personal will help you to share your object
                  </Typography>
                </m.div>
              </ContentStyle>
            </Grid>

            <Grid item xs={12} md={6}>
              <ContentStyle>
                <m.div variants={varFade().inRight}>
                  <HomeFilterForm />
                </m.div>
              </ContentStyle>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </MotionContainer>
  );
}
