import { List, Box } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';
import { NavSectionProps } from '../type';

import { ListSubheaderStyle } from './style';
import NavList from './NavList';

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const { user } = useAuth();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {group.items
            .filter((nav) => {
              if (!nav.permission) {
                return true;
              }

              const pagePermissions = nav.permission;
              const userPermissions = user?.user_role?.map(({ type }: { type: any }) => type);

              return pagePermissions.filter((pPrem) => userPermissions?.includes(pPrem)).length;
            })
            .map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
              />
            ))}
        </List>
      ))}
    </Box>
  );
}
