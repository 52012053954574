import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography, Stack } from '@mui/material';

import { PATH_AUTH } from '../../routes/paths';
import Page from '../../shared/components/Page';
import { RegisterForm } from './sections/register';
import Version from '../../shared/components/Version';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Register() {
  return (
    <Page title="Register">
      <RootStyle sx={{ position: 'relative' }}>
        <Container>
          <ContentStyle>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 5 }}>
              <Link href="/">
                <Box
                  component="img"
                  src="/favicon/android-chrome-192x192.png"
                  sx={{ width: 90, height: 90, cursor: 'pointer' }}
                />
              </Link>
            </Stack>

            <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Get started absolutely free.
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Free forever. No credit card needed.
                </Typography>
              </Box>
            </Box>

            <RegisterForm />

            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
              Already have an account?{' '}
              <Link variant="subtitle2" to={PATH_AUTH.login} component={RouterLink}>
                Login
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
        <Version />
      </RootStyle>
    </Page>
  );
}
