import React, { useState } from 'react';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
import { Switch } from '@mui/material';

import { UserManager } from '../../../../../shared/@types/user';
import Iconify from '../../../../../shared/components/Iconify';
import { TableMoreMenu } from '../../../../../shared/components/table';
import Label, { LabelColor } from '../../../../../shared/components/Label';

type Props = {
  row: UserManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onChangeRealtorFlag: (isRealtor: boolean) => void;
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  onChangeRealtorFlag,
}: Props) {
  const { username, avatarUrl, user_surname, user_role, email } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const isUserRealtor = !!user_role?.find((role) => role.type == 'realtor' || role.type == 'admin');
  const isUserAdmin = !!user_role?.find((role) => role.type == 'admin');
  const roleRole = (type: string) => {
    return { user: 'info', admin: 'success' }[type];
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={username} src={avatarUrl} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {username}
        </Typography>
      </TableCell>

      <TableCell align="left">{user_surname}</TableCell>
      <TableCell align="left">
        {user_role?.map((role, index) => (
          <Label
            key={role.type + index}
            variant={'filled'}
            color={(roleRole(role.type) || 'default') as LabelColor}
            sx={{ textTransform: 'capitalize', marginLeft: '10px' }}
          >
            {role.description}
          </Label>
        ))}
      </TableCell>
      {/*<TableCell align="left">*/}
      {/*  {*/}
      {/*    <Switch*/}
      {/*      checked={isUserRealtor}*/}
      {/*      onChange={() => {*/}
      {/*        if (!isUserAdmin) {*/}
      {/*          onChangeRealtorFlag(!isUserRealtor);*/}
      {/*        }*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  }*/}
      {/*</TableCell>*/}
      <TableCell align="left">{email}</TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
