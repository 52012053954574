import useSettings from '../hooks/useSettings';
import Iconify from '../components/Iconify';
import { Switch, Box } from '@mui/material';

export const ThemeSwitch = () => {
  const { themeMode, onToggleMode } = useSettings();

  const isLightMode = themeMode !== 'light';
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Iconify icon={'fluent-emoji:sun'} width={22} height={22} />
      <Switch
        size="small"
        checked={isLightMode}
        onChange={() => {
          onToggleMode();
        }}
      />
      <Iconify
        icon={'fa-regular:moon'}
        width={20}
        height={20}
        color={isLightMode ? '#fff' : '#212B36'}
      />
    </Box>
  );
};
