import MainLayout from '../../shared/layouts/main';
import HomePage from './Home/HomePage';
import RealEstateList from './RealEstate/RealEstateListWeb';
import RealEstatePublic from './RealEstate/RealEstatePublic';

export default {
  path: '/',
  element: <MainLayout />,
  children: [
    { element: <HomePage />, index: true },
    {
      path: 'featured-listings',
      children: [
        { element: <RealEstateList />, index: true },
        { path: ':id', element: <RealEstatePublic /> },
      ],
    },
  ],
};
