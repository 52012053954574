import React from 'react';

import { Button, Container } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { capitalCase } from 'change-case';

import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../shared/hooks/useSettings';
import Page from '../../../shared/components/Page';
import HeaderBreadcrumbs from '../../../shared/components/HeaderBreadcrumbs';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import Iconify from '../../../shared/components/Iconify';
import { RealEstateNewForm } from './sections';

import { getDemoRealEstate } from '../DemoData';

export default function RealEstateCreateAndUpdate() {
  const { themeStretch } = useSettings();
  const [currentRealEstate, setCurrentRealEstate] = useState();
  const [demoValue, setDemoValue] = useState<any>();

  const { pathname } = useLocation();

  const { id = '' } = useParams();

  useEffect(() => {
    if (id) {
      API.get<any>(API_ENDPOINTS.GET_REAL_ESTATE.replace(':id', id)).then(({ data }) => {
        if (data) {
          setCurrentRealEstate(data.estateObject);
        }
      });
    }
  }, []);

  const isEdit = pathname.includes('edit');
  return (
    <Page title="Blog: New Post">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new real estate' : 'Edit real estate'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Real estate', href: PATH_DASHBOARD.realEstate.root },
            { name: isEdit ? capitalCase(id) : 'New real estate' },
          ]}
          action={
            <Button
              color="warning"
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => {
                setDemoValue(getDemoRealEstate());
              }}
            >
              Set Demo Values
            </Button>
          }
        />
        <RealEstateNewForm
          isEdit={isEdit}
          currentRealEstate={currentRealEstate}
          demoValue={demoValue}
        />
      </Container>
    </Page>
  );
}
