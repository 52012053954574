import { capitalCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../shared/hooks/useSettings';
import Page from '../../../shared/components/Page';
import HeaderBreadcrumbs from '../../../shared/components/HeaderBreadcrumbs';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import { UserManager } from '../../../shared/@types/user';

import UserNewForm from './sections/UserNewForm';
import UserEditForm from './sections/UserEditForm';

export default function UserCreateAndUpdate() {
  const { themeStretch } = useSettings();
  const [currentUser, setCurrentUser] = useState<UserManager>();

  const { pathname } = useLocation();

  const { id = '' } = useParams();

  useEffect(() => {
    if (id) {
      API.get<UserManager>(API_ENDPOINTS.GET_USER.replace(':id', id)).then(({ data }) => {
        if (data) {
          setCurrentUser(data);
        }
      });
    }
  }, []);

  const isEdit = pathname.includes('edit');

  return (
    <Page title="User: Create a new user">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new user' : 'Edit user'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'User', href: PATH_DASHBOARD.user.list },
            { name: !isEdit ? 'New user' : capitalCase(id) },
          ]}
        />

        {isEdit ? <UserEditForm isEdit={isEdit} currentUser={currentUser} /> : <UserNewForm />}
      </Container>
    </Page>
  );
}
