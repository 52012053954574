import { Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Stack, Link, Typography, Container } from '@mui/material';

import { PATH_AUTH } from '../../routes/paths';
import Page from '../../shared/components/Page';
import { LoginForm } from './sections/login';
import Version from '../../shared/components/Version';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    position: 'relative',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0, 0),
}));

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 5 }}>
              <Link href="/">
                <Box
                  component="img"
                  src="/favicon/android-chrome-192x192.png"
                  sx={{ width: 90, height: 90, cursor: 'pointer' }}
                />
              </Link>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Delek Homes
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <LoginForm />

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
        <Version />
      </RootStyle>
    </Page>
  );
}
