import { Card, Container, Box } from '@mui/material';

import { PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../shared/hooks/useAuth';
import useSettings from '../../../shared/hooks/useSettings';
import Page from '../../../shared/components/Page';
import HeaderBreadcrumbs from '../../../shared/components/HeaderBreadcrumbs';

import { ProfileFavorites, ProfileCover } from './sections/profile';
import { _userAbout } from './mock/user';

export default function UserProfile() {
  const { themeStretch } = useSettings();

  const { user } = useAuth();

  return (
    <Page title="User: Profile">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Profile"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: user?.displayName || '' },
          ]}
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative',
          }}
        >
          <ProfileCover myProfile={_userAbout} />
        </Card>

        <Box>
          <ProfileFavorites />
        </Box>
      </Container>
    </Page>
  );
}
