const DemoData = {
  title: [
    'Big Bear Home',
    'Chicago Pizza House',
    'QA House',
    'San Diego Bungalow',
    'Desert Land',
    'Mountain house',
    'Dream House New',
    'Marina Villa',
    'Malibu House of Paradise',
    'Chicago Yankees Home',
    'Apartment2',
    'Tropical Home A',
  ],
  address: [
    '4527 w 142nd st',
    '2122 N Mobile Ave',
    '4527 w 142nd st',
    '2343 No Way st',
    '1 Joshua Tree st',
    '1102 W Babcock St',
    '8481 Southwestern Blvd',
    '4528 w 142nd st',
    '89803 Cuthbert Rd',
    '1717 N McDicker Ave',
    '8985 S Durango dr',
    '7848 Florida St',
  ],
  description: [
    'The big bear home',
    'Come see this impeccably maintained 4 bed, 2 full bath, 2 half bath home in the heart of Palmer Square!',
    'The QA House',
    'North Park is one of San Diego’s first urban neighborhoods mixing both commercial and residential properties giving its owners a true sense of community in a walkable neighborhood. With the largest collection of bungalows in the nation, it is no wonder why so many buyers flock to this area to get a piece of history to call their own. Soon after the extension of the trolley line over the Switzer Canyon Bridge, the expansion of North Park continued.',
    'Desert Land For Sale',
    'test',
    'Little gem',
    'Great home',
    'Set at the end of a gated driveway on a quiet hilltop overlooking miles of Malibu shoreline, 29803 Cuthbert Road is a newly completed 4-bedroom, 4-bathroom home! Come check this!',
    'Sunny and spacious 4 bed, 1 bath home in the very desirable Galewood. Well maintained home with low taxes. Close to shopping, bars, restaurants, and entertainment. Borders Oak Park. Come see it oneday :)',
    'Good apartment',
    'Enjoy the beautiful views in this beach side home!',
  ],
  city: [
    'Hawthorne',
    'Chicago',
    'Hawthorne',
    'San Diego',
    'Joshua Tree',
    'Bozeman',
    'Dallas',
    'Marina Del Ray',
    'Malibu',
    'Chicago',
    'Las Vegas',
    'Boca Raton',
  ],
  state: ['CA', 'IL', 'CA', 'CA', 'CA', 'Montana', 'TX', 'CA', 'CA', 'IL', 'Nevada', 'Florida'],
  zipCode: [
    '90250',
    '90963',
    '90250',
    '91942',
    '92252',
    '59715',
    '75206',
    '90292',
    '90111',
    '60639',
    '89113',
    '32867',
  ],
  price: [
    799999, 401000, 999999, 3000000, 370000, 1000000, 589000, 1000000, 7000000, 301000, 240000,
    1000000,
  ],
  bedrooms: [3, 3, 2, 4, 0, 3, 1, 3, 5, 4, 2, 5],
  bathrooms: [2, 2, 1, 4, 0, 3, 1, 2, 4, 2, 2, 4],
  garage: [1, 1, 1, 1, 0, 2, 1, 0, 2, 0, 0, 2],
  sqft: [2000, 2000, 924, 2060, 0, 250, 1234, 2222, 4000, 2493, 1024, 3000],
  lotSize: [6666, 3000, 5600, 2, 5000, 500, 200, 4444, 9200, 4000, 0, 600],
  images: [
    'https://delekhomes.com/media/photos/2022/08/05/Screen_Shot_2022-08-04_at_10.49.48_PM.png',
    'https://delekhomes.com/media/photos/2022/08/05/Screen_Shot_2022-08-04_at_10.49.57_PM.png',
    'https://delekhomes.com/media/photos/2022/08/05/home-3.jpg',
    'https://delekhomes.com/media/photos/2022/08/23/Screen_Shot_2022-08-23_at_2.48.30_PM.png',
    'https://delekhomes.com/media/photos/2022/08/23/Screen_Shot_2022-08-23_at_2.52.35_PM.png',
    'https://delekhomes.com/media/photos/2022/08/23/Screen_Shot_2022-08-23_at_2.57.30_PM.png',
    'https://delekhomes.com/media/photos/2022/08/28/5aba5c647708e923f116f21b.webp',
    'https://delekhomes.com/media/photos/2022/08/28/c6ebc3df67334aff7e6945a2f33c3523-uncropped_scaled_within_1536_1152.webp',
    'https://delekhomes.com/media/photos/2022/09/16/foto1_cH7uaiM.webp',
    'https://delekhomes.com/media/photos/2022/09/23/1_Bu83Od5.jpg',
    'https://delekhomes.com/media/photos/2022/09/28/Home1.jpeg',
    'https://delekhomes.com/media/photos/2022/09/29/maxresdefault-2_xnEeSM6.jpg',
  ],
  isPublished: [false, false, false, false, false, false, false, false, false, false, false, false],
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function getDemoRealEstate() {
  const estateDate = Object.keys(DemoData).reduce((acc, key) => {
    // @ts-ignore
    const propsValues = DemoData[key];

    if (key === 'images') {
      // @ts-ignore
      acc[key] = [propsValues[getRandomInt(11)]];
    } else {
      // @ts-ignore
      acc[key] = propsValues[getRandomInt(11)];
    }

    return acc;
  }, {});

  return estateDate;
}
