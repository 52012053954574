import { PATH_DASHBOARD } from '../../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  menuItem: getIcon('ic_menu_item'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        permission: ['admin'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
        ],
      },
      {
        title: 'Real Estate',
        path: PATH_DASHBOARD.realEstate.root,
        icon: ICONS.booking,
        permission: ['admin', 'realtor'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.realEstate.list },
          { title: 'create', path: PATH_DASHBOARD.realEstate.new },
          { title: 'inquiries', path: PATH_DASHBOARD.realEstate.inquiries },
        ],
      },
    ],
  },
];

export default navConfig;
