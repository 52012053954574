import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Typography, DialogActions, Grid, Divider } from '@mui/material';

import Markdown from '../../../../shared/components/Markdown';
import Scrollbar from '../../../../shared/components/Scrollbar';
import EmptyContent from '../../../../shared/components/EmptyContent';
import { DialogAnimate } from '../../../../shared/components/animate';

import { FormValuesProps } from './RealEstateNewForm';
import CarouselThumbnail from '../../../../shared/components/carousel-types/CarouselThumbnail';
import Iconify from '../../../../shared/components/Iconify';
import { priceFormat } from '../../../../shared/utils/priceFormat';
import { fDate } from '../../../../shared/utils/formatTime';
import React from 'react';
import useSettings from '../../../../shared/hooks/useSettings';
import Version from '../../../../shared/components/Version';

type Props = {
  values: FormValuesProps;
  isOpen: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
};

export default function RealEstateNewPreview({
  values,
  isValid,
  isSubmitting,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const { title, description } = values;
  const { themeStretch } = useSettings();
  const hasContent = title || description;

  return (
    <DialogAnimate fullScreen open={isOpen} onClose={onClose} sx={{ position: 'relative' }}>
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          Preview Post
        </Typography>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!isValid}
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Post
        </LoadingButton>
      </DialogActions>

      {hasContent ? (
        <Scrollbar>
          <Container>
            <Container maxWidth={themeStretch ? false : 'lg'} style={{ marginTop: 100 }}>
              {values && (
                <>
                  <Box sx={{ position: 'relative' }}>
                    <CarouselThumbnail images={values.images} />
                  </Box>

                  <Grid container spacing={1} marginBottom={2}>
                    <Grid item xs={8} md={8} sm={12}>
                      <Typography variant="h3" sx={{ color: 'text.primary' }}>
                        {values.title}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary' }}>
                        <Iconify icon={'carbon:location-filled'} marginRight={1} />
                        {values.address}
                      </Typography>

                      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                      <Grid container spacing={2}>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:money'} marginRight={1} /> Asking Price:{' '}
                          {priceFormat(values?.price ? values.price : 0)}
                        </Grid>

                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:th-large'} marginRight={1} /> Square Feet:{' '}
                          {values.sqft}
                        </Grid>

                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:square'} marginRight={1} /> Lot Size: {values.lotSize}
                        </Grid>

                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:calendar'} marginRight={1} /> Listing Date:{' '}
                          {fDate(new Date())}
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:car'} marginRight={1} /> Garage: {values.garage}
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:bed'} marginRight={1} /> Bedrooms: {values.bedrooms}
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          <Iconify icon={'fa:bath'} marginRight={1} /> Bathrooms: {values.bathrooms}
                        </Grid>

                        <Grid item xs={12}>
                          <Box sx={{ paddingTop: 5 }}>
                            <Markdown children={values.description} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Container>
          </Container>
        </Scrollbar>
      ) : (
        <EmptyContent title="Empty content" />
      )}
      <Version />
    </DialogAnimate>
  );
}
